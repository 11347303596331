import React, {useState} from 'react';
import './Header.css';
import Video from './../assets/video.mp4';

import { Navigation } from './Navigation';
import Photo from './../assets/photo.png';
import { FixedBandcamp } from './FixedBandcamp';

const Header = () => {
   let [showSkeleton, setShowSkeleton] = useState(false);
   let count = 0;
 
   const clickMe = () => {
     if (count > 10) return;
     count++;
   }

    return <header>
        <Navigation />
         <div className="header-content">
            {showSkeleton ? 
               <div className="video-container">
                     <video className="video" src={Video} controls autoPlay></video>
               </div>
               : <img src={Photo} onClick={clickMe} alt="Shawn Boulet" />
            }
            <div className="header-text">
               <h1>Shawn Boulet</h1>
               <p className="tagline">Pianist, arranger and composer.</p>
               <div>
                  <p>Shawn Boulet is a Toronto based musician and composer whose atmospheric sound has been featured in both film and live theatre.</p>
                  <a href="https://shawnboulet.bandcamp.com" onClick={e => {
                     if (count === 10) {
                        e.preventDefault();
                        setShowSkeleton(true);
                        setTimeout(() => {
                           setShowSkeleton(false);
                        }, 30000);
                     }
                  }} className="button">shawnboulet.bandcamp.com</a>
               </div>
            </div>
         </div>
         <FixedBandcamp />
    </header>
}

export {Header};