import React from 'react';
import './FixedBandcamp.css';

const FixedBandcamp = () => {
   return <>
      <div className="fixed-bandcamp">
        <iframe 
            title="The 6th Degree on Bandcamp"
            className="standard"
            src="https://bandcamp.com/EmbeddedPlayer/album=3985013754/size=large/bgcol=333333/linkcol=ffcc66/tracklist=false/artwork=small/transparent=true/"
         />
         <iframe 
            title="The 6th Degree on Bandcamp (Mini)"
            className="mini"
            src="https://bandcamp.com/EmbeddedPlayer/album=3985013754/size=small/bgcol=333333/linkcol=ffcc66/transparent=true/"
         />
      </div>
      <div className="bandcamp-feature">
         <p>Listen to <i>The 6th Degree - Music from the Immersive Crime Investigation Production</i> on Bandcamp. Released May 7th, 2022.</p>
      </div>
   </>
}

const BandcampDock = () => {
   return <div className="bandcamp-dock"></div>
}

export {FixedBandcamp, BandcampDock};