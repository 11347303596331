import React from 'react';
import './About.css'

const About = () => {
    return (
    <section id="about">
		<h2>About</h2>
		<p>Drawing musical inspiration from a wide variety of training and styles, Shawn Boulet has enjoyed a diverse career as both a classically trained pianist and an electronic-based composer. Shawn first began studying the piano at age 12 and immediately fell in love with studying the instrument as well as the art of performance. It was at this time that he began to develop his proficiency in piano, completing piano exams at an intensive pace. On top of his affection for classical music, Shawn also began developing an ear for film and video game scores and incorporating them into his repertoire.
		</p>
		<p>This passion is what eventually led Shawn Boulet to pursue music full time starting with a post-secondary education in music. In 2016, Shawn Boulet earned a <i>Bachelor of Fine Arts with Specialized Honours in Music</i>, where he studied classical piano performance, accompaniment, and composition, and is now working on completing his Masters Degree in <i>Music Technology and Digital Media</i>. During his studies, Shawn established himself as a formidable arranger and composer, creating original arrangements of video game music for orchestras on top of performing on various stages as a soloist and accompanist.
		</p>
		<p>Using his wide variety of expertise, Shawn Boulet now holds a position as <i>Resident Musical Director &amp; Composer</i> for <a href="https://www.thelighthousetroupe.com/">The Lighthouse Troupe</a> (a Canadian collective that produces experiential site-specific and interactive theatre productions) as well as piano accompanist for York University's Department of Dance. Shawn Boulet has the unique privilege of composing the score for every Lighthouse Troupe production to date, creating horror-based atmospheres that underscore specific rooms and characters as opposed to picture.
		</p>
		<p>Soon, Shawn Boulet will be taking his love for horror-based media and applying it to scoring more independent film projects as well as working towards uploading more piano resources on his <a href="https://www.youtube.com/channel/UChBal59lsNV1x3NmF5jd5Rw">YouTube channel</a>. With no shortage of ambition, Shawn looks forward to working through many more creative challenges with many more like minded artists.
		</p>
    </section>)
}

export {About};