import React, { useState } from 'react';
import './Projects.css'
import Ruthie from './../assets/ruthie.png'

const Projects = () => {
    return (
    <section id="projects">
		<h2>Projects</h2>
		<div className="project-list">
			<div className="project-header">
				<h3>Theatre Productions</h3>
				<p>
					Scores and soundtracks I've written and produced for <a href="https://www.thelighthousetroupe.com/">The Lighthouse Troupe</a>.
				</p>
			</div>
			<div className="project">
				<div className="artwork">
					<iframe title="The 6th Degree" className="artwork-only" src="https://bandcamp.com/EmbeddedPlayer/album=3985013754/size=large/bgcol=333333/linkcol=ffcc66/minimal=true/transparent=true/" />
					<a href="https://shawnboulet.bandcamp.com/album/the-6th-degree-music-from-the-immersive-crime-investigation-production" aria-label="The 6th Degree - Music from the Immersive Crime Investigation Production" className="button">Listen on Bandcamp</a>
				</div>
				<div className="project-name">
					<h4>The 6th Degree</h4>
					<h5>Music from the Immersive Crime Investigation Production</h5>
					<h6>Released May 7, 2022</h6>
					<a href="https://shawnboulet.bandcamp.com/album/the-6th-degree-music-from-the-immersive-crime-investigation-production" aria-label="The Wedding - Music from the Experiential Horror/Comedy" className="button">Listen on Bandcamp</a>
				</div>
				<div className="accompaniment-text">
					<p>
						Many challenges came with scoring The Lighthouse Troupe’s most ambitious and creative production to date. 
						Beginning with the unsolved murder of 30 year old Chloe Traeger, this experiential and interactive 
						production has each audience member inhabit the role of a detective, traveling room to room interrogating 
						suspects in real time. Each room housed a different suspect, along with their music that underscored each 
						interrogation, setting a unique and usually foreboding atmosphere that develops over three rounds. The 
						score had to accomplish a lot; each character needed a unique sound that had to become more dire as the 
						evening got closer to ending, while at the same time be very unobtrusive as audience members would be 
						engaging in real interrogation with actors. Coming up with creative solutions to obstacles like these 
						is what I believe makes composition so rewarding, and the result ended up being a project I can be very 
						proud of.
					</p>
				</div>
			</div>

			<div className="project">
				<div className="artwork">
					<iframe title="The Wedding" className="artwork-only" src="https://bandcamp.com/EmbeddedPlayer/album=2716107447/size=large/bgcol=333333/linkcol=ffcc66/minimal=true/transparent=true/" />
					<a href="https://shawnboulet.bandcamp.com/album/the-wedding-music-from-the-experiential-horror-comedy" aria-label="The Wedding - Music from the Experiential Horror/Comedy" className="button">Listen on Bandcamp</a>
				</div>
				<div className="project-name">
					<h4>The Wedding</h4>
					<h5>Music from the Experiential Horror/Comedy</h5>
					<h6>Released February 4, 2022</h6>
					<a href="https://shawnboulet.bandcamp.com/album/the-wedding-music-from-the-experiential-horror-comedy" aria-label="The Wedding - Music from the Experiential Horror/Comedy" className="button">Listen on Bandcamp</a>

				</div>
				<div className="accompaniment-text">
					<p>
						Working with The Lighthouse Troupe in the past, I jumped at the opportunity to score my first theatrical 
						production with the company. Set in the middle of a seemingly ordinary wedding reception, horror unfolds as 
						the true identities of its guests are revealed and a bizarre cult begins to disclose the true reason for this 
						couple’s matrimony. The score for this production was written for a live jazz band and would be performed 
						live during the run of the play. The music would begin as light jazz covers and original compositions that 
						one might hear at a wedding reception, but would progressively become more sinister as the religious cult 
						behind the scenes would begin to surface and perform their own ceremony. Utilizing my performance, arrangement, 
						and composition skills for this production created a very fun and challenging experience not only for myself, 
						but for the band performing the music as well as the audience.
					</p>
				</div>
			</div>
		</div>

		<div className="project-list">
			<div className="project-header">
				<h3>Film Scores</h3>
				<p>
					Scores I have composed for independent films.
				</p>
			</div>

			<div className="project">
				<div className="artwork">
					<img src={Ruthie} className="artwork-only" alt="For Ruthie screenshot" />
					<a href="https://www.youtube.com/watch?v=b52qsSAOoMc" aria-label="For Ruthie -A film written and directed by Susan Freed 2018" className="button">Watch on YouTube</a>
				</div>
				<div className="project-name">
					<h4>For Ruthie</h4>
					<h5>A film written and directed by Susan Freed</h5>
					<h6>Released 2018</h6>
					<a href="https://www.youtube.com/watch?v=b52qsSAOoMc" aria-label="For Ruthie -A film written and directed by Susan Freed 2018" className="button">Watch on YouTube</a>
				</div>
				<div className="accompaniment-text">
					<p>
						For Ruthie is a dramatic short written and directed by Susan Freed about a family coming to terms with the 
						tragic loss of a child. I was very excited to be working on a project that provided an outlet for me to 
						write a score completely for solo piano. Being able to write at the piano first allowed me to focus on the 
						fundamentals of piano-based film composition, and take simple melodic ideas and develop them for picture.
					</p>
				</div>
			</div>
		</div>

		<div className="project-list">
			<div className="project-header">
				<h3>Accompaniment</h3>
				<p>
					A collection of accompaniment pieces performed for solo piano.
				</p>
			</div>
			<Accompaniment />
		</div>
    </section>)
}

const Accompaniment = () => {
	let [iframeRef, setIframeRef] = useState("https://www.youtube.com/embed/3NbDHhsHhmE");

	const playVideo = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string) => {
		setIframeRef("https://www.youtube.com/embed/" + href);
	}

	return <div className="project">
		<div className="artwork youtube-container">
		<iframe className="youtube-only" src={iframeRef} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
		</div>
		<div className="accompaniment-text">
			<p>Here you can find a series of videos I have recorded for my <a href="https://www.youtube.com/channel/UChBal59lsNV1x3NmF5jd5Rw" aria-label="YouTube channel">YouTube channel</a> in order to help classical vocalists practice some popular repertoire. Using my training as a classical pianist, I’ve compiled a few piano accompaniment pieces I’ve played throughout the years in order to give vocalists something to practice to as well as draw attention to often forgotten accompaniment parts of some famous pieces.</p>
			<div className="youtube-links">
				<a href="https://www.youtube.com/watch?v=3NbDHhsHhmE" onClick={e => {e.preventDefault(); playVideo(e, "3NbDHhsHhmE")}} aria-label="Grieg ~ Melodies of the Heart, Op.5 No. 3 (Accompaniment)">Grieg ~ Melodies of the Heart, Op.5 No. 3</a>
				<a href="https://www.youtube.com/watch?v=I_NcE2nzjcA" onClick={e => {e.preventDefault(); playVideo(e, "I_NcE2nzjcA")}} aria-label="Puccini - La Bohème - Donde Lieta Usci (Accompaniment)">Puccini - La Bohème - Donde Lieta Usci</a>
				<a href="https://www.youtube.com/watch?v=7-AivR3h8us" onClick={e => {e.preventDefault(); playVideo(e, "7-AivR3h8us")}} aria-label="Schumann - Dichterliebe No.1 (Accompaniment)">Schumann - Dichterliebe No.1</a>
			</div>
		</div>
	</div>
}

export {Projects, Accompaniment};