import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import reportWebVitals from './reportWebVitals';
import { Header } from './Header/Header';
import { About } from './Sections/About';
import { BandcampDock } from './Header/FixedBandcamp';
import { Contact } from './Sections/Contact';
import { Footer } from './Footer/Footer';
import { Projects } from './Sections/Projects';

let body = document.getElementsByTagName('body');

if (body.length > 0) {
	const root = ReactDOM.createRoot(
		body[0]
	);

	root.render(
	<React.StrictMode>
		<Header />
		<Projects />
		<About />
		<Contact />
		<Footer />
		<BandcampDock />
	</React.StrictMode>
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
