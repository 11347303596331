import React from 'react';
import './Navigation.css';

const Navigation = () => {
    return (
    <nav>
        <ul>
            <li id="nav-about">
                <a href="#projects">Projects</a>
            </li>
            <li id="nav-projects">
                <a href="#about">About</a>
            </li>
            <li id="nav-contact">
                <a href="#contact">Contact</a>
            </li>
        </ul>
    </nav>
    )
}

export {Navigation};