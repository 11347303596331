import React from 'react';
import './BusinessCard.css';
import ShawnBouletLogo from './../assets/shawnboulet.png';
import BandcampLogo from './../assets/bandcamp.png';
import YouTubeLogo from './../assets/youtube.png';

const BusinessCard = () => {
    return (
    <div className="business-card">
        <div>
            <div className="left">
                <div>
                    <img src={ShawnBouletLogo} alt="Shawn Boulet" />
                </div>
            </div>
            <div className="right">
                <div>
                <a href="https://shawnboulet.bandcamp.com" aria-label="Visit Shawn Boulet's bandcamp website">
                    <img src={BandcampLogo} alt="Bandcamp" />
                </a>
                <a href="https://shawnboulet.bandcamp.com" aria-label="Visit Shawn Boulet's bandcamp website">
                    <span>shawnboulet.bandcamp.com</span>
                </a>
                </div>
                <div>
                <a href="https://www.youtube.com/channel/UChBal59lsNV1x3NmF5jd5Rw" aria-label="Visit Shawn Boulet's YouTube page">
                    <img src={YouTubeLogo} alt="YouTube" />
                </a>
                <a href="https://www.youtube.com/channel/UChBal59lsNV1x3NmF5jd5Rw" aria-label="Visit Shawn Boulet's YouTube page">
                    <span>YouTube channel</span>
                </a>
                </div>
            </div>
        </div>
    </div>)
}

export {BusinessCard};