import React, { useState, useId } from 'react';
import './Contact.css'

enum FormInputType {
	Input, Textarea
}

interface FormInputProp {
	name: string;
	type?: FormInputType;
	input: string;
	setInput: React.Dispatch<React.SetStateAction<string>>;
}

const FormInput = (props: FormInputProp) => {
	const id = useId();

	return <div>
		<label htmlFor={id}>{props.name}</label>
		{props.type === FormInputType.Textarea ?
			<textarea id={id} maxLength={2000} onInput={
				(e) => props.setInput(e.currentTarget.value)
			} value={props.input} /> :
			<input type="text" id={id} onInput={
				(e) => props.setInput(e.currentTarget.value)
			} value={props.input} />
		}
	</div>
}

const Contact = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const [formDisabled, setFormDisabled] = useState(false);
	const [showErrorSuccess, setShowErrorSuccess] = useState(false);

	const [error, setError] = useState(false);
	const [errorSuccessMessage, setErrorSuccessMessage] = useState("");
	const [buttonMessage, setButtonMessage] = useState("Send message");

	const validateName = (name: string) => {
		return name.length > 0 && name.length < 255;
	};

	const validateEmail = (email: string) => {
		if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.toLowerCase())) {
			return (true);
		}
		return (false);
	};

	const validateMessage = (message: string) => {
		return message.length > 0 && message.length < 2000;
	};

	const resetShowMessage = () => {
		setShowErrorSuccess(false);
		setFormDisabled(false);
	}

	const submitForm = () => {
		setFormDisabled(true);
		setButtonMessage("Sending...");

		if (!validateName(name)) {
			setError(true);
			setErrorSuccessMessage("Error! Enter a valid name.");
		} else if (!validateEmail(email)) {
			setError(true);
			setErrorSuccessMessage("Error! Enter a valid email.");
		} else if (!validateMessage(message)) {
			setError(true);
			setErrorSuccessMessage("Error! Enter a valid message.");
		} else {
			let jsonMessage = JSON.stringify({
				'name': name,
				'email': email,
				'message': message
			});

			let xhr = new XMLHttpRequest();
			xhr.open("POST", "https://tnm9ujwv3i.execute-api.us-east-1.amazonaws.com/default/SendContactEmail");
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.onload = () => {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						let json = JSON.parse(xhr.responseText);
						if (json.success) {
							setError(false);
							setName("");
							setEmail("");
							setMessage("");
							setErrorSuccessMessage("Your message has been sent.");
						} else {
							setError(true);
							setErrorSuccessMessage("Error! Server error. Try later.");
						}
					} else {
						setError(true);
						setErrorSuccessMessage("Error! Server error. Try later.");
					}
				}
			};
			xhr.onerror = () => {
				setError(true);
				setErrorSuccessMessage("Error! Server error. Try later.");
			};
			xhr.send(jsonMessage);
		}

		setShowErrorSuccess(true);
		setButtonMessage("Send message");

		setTimeout(() => {
			resetShowMessage();
		}, 10000);
	}

    return (
		<section id="contact">
			<h2>Contact</h2>
			<form className="contact-form" onSubmit={e => {
				e.preventDefault();
				submitForm();
			}}>
				<fieldset className="contact-fieldset" disabled={formDisabled}>
					{showErrorSuccess?
						<>
						<div className="error-message" onClick={resetShowMessage}>
							<span style={{color: error? "#ff6666" : "#fff"}}>{errorSuccessMessage}</span>
						</div>
						<div className="error-bg" onClick={resetShowMessage}></div>
						</> : <></>
					}
					<FormInput name="Name" input={name} setInput={setName} />
					<FormInput name="Email" input={email} setInput={setEmail} />
					<FormInput type={FormInputType.Textarea} name="Message" input={message} setInput={setMessage} />
					<div className="form-submit">
						<button disabled={formDisabled} className="button" onClick={e => {
							e.preventDefault();
							submitForm()
						}}>
							{buttonMessage}
						</button>
					</div>
				</fieldset>
			</form>
		</section>)
}

export {Contact};