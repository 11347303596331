import React from 'react';
import './Footer.css';
import { BusinessCard } from './BusinessCard';

const Footer = () => {
  return (
    <footer>
      <BusinessCard />
      <div>
        <span><a href="#top" className="button">Back to the top</a></span>
        <span>|</span>
        <span>Copyright &copy; Shawn Boulet, 2022.</span>
      </div>
    </footer>)
}

export {Footer};